export interface ModuleDetails {
  module: string;
  version: string;
  time: string;
}

declare global {
  interface Window {
    VERSIONS: ModuleDetails[];
  }
}

// Return version information for other diimos modules, which is deployed to
// public/versions.js at deployment time then loaded into the window object.
export const VersionData = (
  process.env.NODE_ENV === 'development' ? [] : window.VERSIONS
) as ModuleDetails[];
