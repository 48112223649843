import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { ModuleDetails, VersionData } from 'utils/versions';
import { Box } from '@mui/system';
import './module.css';

const Version = () => {
  if (!VersionData || VersionData.length < 1) {
    return (
      <Box>
        <Typography>No version information to display.</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {VersionData.map((module: ModuleDetails) => (
          <VersionItem module={module} />
        ))}
      </Grid>
    </Box>
  );
};

interface VersionItemProps {
  module: ModuleDetails;
}

const VersionItem = (props: VersionItemProps) => {
  const { module } = props;

  const version = module.version.substring(0, 10);
  const updateDate = new Date(module.time);
  const timestamp = updateDate.toLocaleTimeString() + ' - ' + updateDate.toLocaleDateString();

  return (
    <Grid item xs={10} sm={6} key={module.module} alignContent="center">
      <Card className="tile-card">
        <CardActionArea>
          <CardContent>
            <div className="heading">{module.module}</div>
            <Typography className="textLabel">
              <b>Version: </b>
              {version}
            </Typography>
            <Typography className="textLabel">
              <b>Date: </b>
              {timestamp}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default Version;
