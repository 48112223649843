import React from 'react';
import { Copyright } from '@mui/icons-material';
import { Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';
import { DateTime } from 'luxon';

const CopyRightField = styled(Typography)({
  fontSize: 12,
  textAlign: 'center',
  letterSpacing: 0.4,
  opacity: 0.8,
});
const CopyrightIcon = styled(Copyright)({
  opacity: 0.8,
  width: 11,
  height: 11,
});

const LoginFooter: React.FC = () => {
  const year = DateTime.now().year;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="baseline"
      flexDirection="row"
      width="inherit"
      mt={3}
    >
      <CopyRightField>
        {`Copyright`}
        <CopyrightIcon sx={{ marginX: '2px' }} />
        <Link href="https://www.idoba.com/" target="_blank">
          idoba
        </Link>
        {` ${year}`}
      </CopyRightField>
    </Box>
  );
};

export default LoginFooter;
