import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';

const TextLabel = styled(Typography)({
  width: 'inherit',
  marginBottom: 0,
  minHeight: 24,
  fontSize: 16,
  lineHeight: '150%',
  letterSpacing: 0.15,
  '&.text-heading': {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '175%',
    letterSpacing: 0.15,
    marginBottom: 1,
  },
  '&.text-heading-small': {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '166%',
    letterSpacing: 0.4,
    alignSelf: 'stretch',
  },
});

export default TextLabel;
