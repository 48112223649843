import { IdobaSecurity, OptikaApp } from '@optika-solutions/shared-react';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routing from 'Routing';
import authService from 'services/authService';

import store from './store/Store';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <OptikaApp>
        <IdobaSecurity authService={authService}>
          <BrowserRouter>
            <Routing />
          </BrowserRouter>
        </IdobaSecurity>
      </OptikaApp>
    </Provider>
  );
};

export default App;
