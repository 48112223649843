import React from 'react';

const IdobaLogin: React.FC = () => (
  <svg
    width="436"
    height="140"
    viewBox="0 0 436 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ minWidth: '100%' }}
  >
    <g clipPath="url(#clip0_606_33756)">
      <rect width="436" height="140" rx="12" fill="white" />
      <g clipPath="url(#clip1_606_33756)">
        <path d="M436 0H0V237H436V0Z" fill="#1A1746" />
        <path opacity="0.1" d="M209.891 70.1444H140V139.961H209.891V70.1444Z" fill="#57408F" />
        <path opacity="0.2" d="M350.109 0H280.219V70.1444H350.109V0Z" fill="#57408F" />
        <path d="M279.891 70.1444H210V139.961H279.891V70.1444Z" fill="#57408F" fillOpacity="0.5" />
        <path opacity="0.2" d="M436 70.1444H350.109V139.961H436V70.1444Z" fill="#57408F" />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210 70.1444C171.22 70.1444 139.781 38.739 139.781 0H210V70.1444Z"
          fill="#57408F"
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M436 70.1444C381.22 70.1444 349.781 38.739 349.781 0H436V70.1444Z"
          fill="#57408F"
        />
        <path
          opacity="0.25"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M280 70.1444C318.78 70.1444 350.219 101.401 350.219 139.961L280 139.961L280 70.1444Z"
          fill="#57408F"
        />
        <path
          opacity="0.15"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M279.891 35.0722C279.891 54.4417 264.246 70.1444 244.948 70.1444C225.649 70.1444 210 54.4417 210 35.0722C210 15.7027 225.645 0 244.948 0C264.25 0 279.891 15.7027 279.891 35.0722Z"
          fill="#57408F"
        />
        <path
          opacity="0.15"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M139.891 105.051C139.891 124.333 124.246 139.961 104.943 139.961C85.6406 139.961 70 124.333 70 105.051C70 85.7685 85.645 70.1444 104.947 70.1444C124.25 70.1444 139.895 85.7729 139.895 105.051H139.891Z"
          fill="#57408F"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M209.891 105.051C209.891 124.333 194.246 139.961 174.948 139.961C155.649 139.961 140 124.333 140 105.051C140 85.7685 155.645 70.1444 174.948 70.1444C194.25 70.1444 209.891 85.7729 209.891 105.051Z"
          fill="#57408F"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M436 105.051C436 124.333 404.281 139.961 384.891 139.961C365.501 139.961 349.781 124.333 349.781 105.051C349.781 85.7685 365.501 70.1444 384.891 70.1444C404.281 70.1444 436 85.7729 436 105.051Z"
          fill="#57408F"
        />
      </g>
      <g clipPath="url(#clip2_606_33756)">
        <path
          d="M125.407 62.8192C128.945 62.8192 131.814 60.0113 131.814 56.5475C131.814 53.0838 128.945 50.2759 125.407 50.2759C121.868 50.2759 119 53.0838 119 56.5475C119 60.0113 121.868 62.8192 125.407 62.8192Z"
          fill="white"
        />
        <path
          d="M140.324 50.2759C140.324 57.1898 146.056 62.8003 153.119 62.8003V50.2759H140.324Z"
          fill="white"
        />
        <path
          d="M153.12 71.227L140.401 72.5822L141.786 85.0327L154.505 83.6776L153.12 71.227Z"
          fill="white"
        />
        <path
          d="M131.794 83.6933C131.794 76.7793 126.063 71.1688 119 71.1688V83.6933H131.794Z"
          fill="white"
        />
        <path d="M180.772 62.8192H176.064V83.6933H180.772V62.8192Z" fill="white" />
        <path
          d="M181.621 56.5287C181.621 54.8285 180.29 53.3928 178.437 53.3928C176.585 53.3928 175.215 54.8285 175.215 56.5287C175.215 58.2855 176.585 59.6645 178.437 59.6645C180.309 59.6645 181.621 58.2855 181.621 56.5287Z"
          fill="white"
        />
        <path
          d="M202.618 54.9608V64.5005C200.784 63.1782 198.507 62.3659 196.037 62.3659C189.9 62.3659 184.921 67.2396 184.921 73.2468C184.921 79.254 189.9 84.1278 196.037 84.1278C198.507 84.1278 200.765 83.3344 202.618 81.9932V83.6555H207.326V54.9419H202.618V54.9608ZM196.056 79.5185C192.525 79.5185 189.649 76.7038 189.649 73.2468C189.649 69.7899 192.525 66.9752 196.056 66.9752C199.588 66.9752 202.463 69.771 202.463 73.2468C202.444 76.7227 199.588 79.5185 196.056 79.5185Z"
          fill="white"
        />
        <path
          d="M222.533 62.3848C216.396 62.3848 211.417 67.2585 211.417 73.2657C211.417 79.2729 216.396 84.1467 222.533 84.1467C228.67 84.1467 233.648 79.2729 233.648 73.2657C233.648 67.2585 228.67 62.3848 222.533 62.3848ZM222.533 79.5185C219.001 79.5185 216.126 76.7038 216.126 73.2468C216.126 69.7899 218.982 66.9752 222.533 66.9752C226.064 66.9752 228.92 69.771 228.92 73.2468C228.94 76.7227 226.064 79.5185 222.533 79.5185Z"
          fill="white"
        />
        <path
          d="M249.029 62.3847C246.559 62.3847 244.301 63.1781 242.448 64.5193V54.9607H237.74V83.6554H242.448V82.012C244.301 83.3343 246.559 84.1466 249.029 84.1466C255.166 84.1466 260.144 79.2728 260.144 73.2656C260.144 67.2584 255.146 62.3847 249.029 62.3847ZM249.029 79.5184C245.497 79.5184 242.622 76.7037 242.622 73.2467C242.622 69.7898 245.497 66.9751 249.029 66.9751C252.56 66.9751 255.436 69.7709 255.436 73.2467C255.416 76.7226 252.56 79.5184 249.029 79.5184Z"
          fill="white"
        />
        <path
          d="M280.291 62.8381V64.5005C278.439 63.1781 276.181 62.3658 273.711 62.3658C267.574 62.3658 262.595 67.2396 262.595 73.2468C262.595 79.254 267.574 84.1278 273.711 84.1278C276.181 84.1278 278.439 83.3344 280.291 81.9931V83.6555H285V62.8381H280.291ZM273.711 79.5185C270.179 79.5185 267.304 76.7038 267.304 73.2468C267.304 69.7898 270.16 66.9751 273.711 66.9751C277.242 66.9751 280.118 69.7709 280.118 73.2468C280.118 76.7227 277.262 79.5185 273.711 79.5185Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_606_33756">
        <rect width="436" height="140" rx="12" fill="white" />
      </clipPath>
      <clipPath id="clip1_606_33756">
        <rect width="436" height="236" fill="white" />
      </clipPath>
      <clipPath id="clip2_606_33756">
        <rect width="166" height="34.7586" fill="white" transform="translate(119 50.2759)" />
      </clipPath>
    </defs>
  </svg>
);
export default IdobaLogin;
