import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Fade, FormGroup } from '@mui/material';

import ErrorLabel from './ErrorLabel';
import PasswordInput from './PasswordInput';
import TextLabel from './TextLabel';
import LinkStyled from 'components/linkStyled/linkStyled';
import { validatePassword } from 'utils/validate';

const SetNewPasswordForm: React.FC<{
  password: string;
  rePassword: string;
  isLoading: boolean;
  hasConfirmationCodeError: boolean;
  email: string;
  setPassword: (newValue: string) => void;
  setRePassword: (newValue: string) => void;
  onSetNewPassword: () => void;
  backToValidationCode: () => void;
}> = ({
  password,
  rePassword,
  isLoading,
  hasConfirmationCodeError,
  email,
  setPassword,
  setRePassword,
  onSetNewPassword,
  backToValidationCode,
}) => {
  const [firstNewPasswordError, setFirstNewPasswordError] = useState('');
  const [secondNewPasswordError, setSecondNewPasswordError] = useState('');

  const validateFirstPassword = (text: string) => {
    const validationError = validatePassword(text, email);

    if (validationError) {
      setFirstNewPasswordError(validationError);
      setPassword(text);
      return;
    }

    setFirstNewPasswordError('');
    setPassword(text);
  };

  const validateSecondPassword = (text: string) => {
    const matches = password === text;
    const validationError = matches ? '' : 'Passwords do not match';
    if (validationError) {
      setSecondNewPasswordError(validationError);
      setRePassword(text);
      return;
    }

    setSecondNewPasswordError('');
    setRePassword(text);
  };

  const handleSubmitKey = () => {
    onSetNewPassword();
  };

  const backToValidationText = 'Back To Validation Submit';

  const displayBackToValidationLink = hasConfirmationCodeError ? (
    <LinkStyled sx={{ paddingTop: '24px' }} onClick={backToValidationCode}>
      {backToValidationText}
    </LinkStyled>
  ) : (
    <></>
  );

  return (
    <React.Fragment>
      <Fade in appear>
        <div>
          <Box sx={{ width: '100%' }} mb={2}>
            <TextLabel className="text-heading" variant="subtitle2" gutterBottom>
              Enter your new DiiMOS password
            </TextLabel>
            <TextLabel className="text-heading-small" variant="body2" gutterBottom>
              Please ensure that your password:
            </TextLabel>

            <ul style={{ margin: 'unset', paddingLeft: '1.5rem' }}>
              <li>
                <TextLabel className="text-heading-small" variant="body2" gutterBottom>
                  is at least 12 characters in length
                </TextLabel>
              </li>
              <li>
                <TextLabel className="text-heading-small" variant="body2" gutterBottom>
                  contains at least 1 uppercase letter, number and special character
                </TextLabel>
              </li>
              <li>
                <TextLabel className="text-heading-small" variant="body2" gutterBottom>
                  does not match your email
                </TextLabel>
              </li>
              <li>
                <TextLabel className="text-heading-small" variant="body2" gutterBottom>
                  is not shared with other site passwords
                </TextLabel>
              </li>
              <li>
                <TextLabel className="text-heading-small" variant="body2" gutterBottom>
                  has no leading, trailing space characters
                </TextLabel>
              </li>
            </ul>
          </Box>
          <FormGroup>
            <PasswordInput
              label="New password"
              name="new-password-01"
              isLoading={isLoading}
              errors={firstNewPasswordError}
              password={password}
              hasPasswordError={!!firstNewPasswordError}
              onChange={validateFirstPassword}
              isNewPassword
            />
            {firstNewPasswordError && <ErrorLabel>{firstNewPasswordError}</ErrorLabel>}
            <PasswordInput
              label="Re-enter your new password"
              name="new-password-02"
              mt={3}
              isLoading={isLoading}
              errors={secondNewPasswordError}
              password={rePassword}
              hasPasswordError={!!secondNewPasswordError}
              onChange={validateSecondPassword}
              onSubmitKeyHandler={handleSubmitKey}
              isNewPassword
            />
            {secondNewPasswordError && <ErrorLabel>{secondNewPasswordError}</ErrorLabel>}
          </FormGroup>
          <Box
            sx={{ mt: 3 }}
            display="flex"
            justifyContent="center"
            alignItems="baseline"
            flexDirection="column"
            width="inherit"
          >
            <LoadingButton
              variant="contained"
              fullWidth={true}
              onClick={handleSubmitKey}
              size="large"
              loading={isLoading}
              disabled={!!(firstNewPasswordError || secondNewPasswordError)}
            >
              Change Your Password
            </LoadingButton>

            {displayBackToValidationLink}
          </Box>
        </div>
      </Fade>
    </React.Fragment>
  );
};

export default SetNewPasswordForm;
