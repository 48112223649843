import React, { useEffect, useState } from 'react';
import { Fade, FormGroup, TextField } from '@mui/material';
import Box from '@mui/system/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextLabel from './TextLabel';
import { checkEmailZod } from 'utils/validate';
import LinkStyled from 'components/linkStyled/linkStyled';
import { loginErrorTypes, loginFormsTypes } from 'utils/types';

const ResetPasswordForm: React.FC<{
  displayView: loginFormsTypes;
  username: string;
  confirmationCode: string;
  setUsername: (newValue: string) => void;
  setConfirmationCode: (newValue: string) => void;
  sendCode: () => void;
  resetPassword: () => void;
  backNavigation: () => void;
  hasUsernameError: boolean;
  hasConfirmationCodeError: boolean;
  isLoading: boolean;
  errors: string;
  hasReadError: (errorType: loginErrorTypes) => void;
}> = ({
  displayView,
  username,
  confirmationCode,
  setUsername,
  setConfirmationCode,
  sendCode,
  resetPassword,
  backNavigation,
  hasUsernameError,
  hasConfirmationCodeError,
  isLoading,
  errors,
  hasReadError,
}) => {
  const [localEmail, setLocalEmail] = useState('');
  const [emailHelperText, setEmailHelperText] = useState('');
  const [localConfirmationCode, setLocalConfirmationCode] = useState(confirmationCode || '');
  const [confirmationCodeHelperText, setConfirmationCodeHelperText] = useState(
    hasConfirmationCodeError || ''
  );
  const emailResponseText = 'Must be email format.';
  const confirmationHelperText = 'Confirmation code must be 6 characters in length.';

  const onEmailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalEmail(e.target?.value);
    if (e.target?.value) {
      const isValidEmail = checkEmailZod(e.target?.value);
      if (!isValidEmail) {
        setEmailHelperText(emailResponseText);
        return;
      }
      setUsername(e.target?.value);
      setEmailHelperText('');
      hasReadError(loginErrorTypes.username);
    }
  };

  // On Render, if autofilled and valid, then send to parent
  useEffect(() => {
    const autofilledIsValid = checkEmailZod(localEmail);
    if (!autofilledIsValid && localEmail) {
      setEmailHelperText(emailResponseText);
      return;
    }

    setUsername(localEmail);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitKeyHandler = (e: { code: string }) => {
    if (e.code === 'Enter') {
      // resetPassword flows with entering confirmation code
      // otherwise it should trigger the request for email validation
      displayView === loginFormsTypes.resetPassword ? resetPassword() : sendCode();
    }
  };

  const onConfirmationCodeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validationCode = e.target?.value || '';
    setLocalConfirmationCode(validationCode);
    //@TODO - getting a validation code of 6 chars consistently, going to use this to do a light validation
    // of expected number of chars for now. Doesnt look like can validate token outside of passing validate code with new password.
    if (validationCode.length === 6) {
      setConfirmationCode(validationCode);
      setConfirmationCodeHelperText('');
      return;
    }

    setConfirmationCodeHelperText(confirmationHelperText);
  };

  return (
    <React.Fragment>
      {displayView === loginFormsTypes.forgetPasswordCode && (
        <Fade in appear>
          <Box sx={{ width: '100%', maxWidth: 500 }} mb={2}>
            <TextLabel className="text-heading" variant="subtitle2" gutterBottom>
              Reset your DiiMOS password
            </TextLabel>
            <TextLabel variant="body1" gutterBottom>
              Enter your email to receive a confirmation code.
            </TextLabel>
            <TextLabel variant="body1" gutterBottom>
              This code will be used to reset your password.
            </TextLabel>
          </Box>
        </Fade>
      )}
      {displayView === loginFormsTypes.resetPassword && (
        <Fade in appear>
          <Box sx={{ width: '100%', maxWidth: 500 }} mb={2}>
            <TextLabel variant="body1" gutterBottom>
              Enter the 6-digit confirmation code from your email
            </TextLabel>
          </Box>
        </Fade>
      )}
      <Fade in appear>
        <div>
          <FormGroup>
            <TextField
              autoComplete="email"
              label={'Email address'}
              value={localEmail}
              onChange={onEmailChangeHandler}
              onKeyDown={onSubmitKeyHandler}
              error={!!emailHelperText}
              helperText={emailHelperText || errors}
              focused={displayView === loginFormsTypes.forgetPasswordCode ? true : false}
              disabled={isLoading ? true : false}
            />
            {displayView === loginFormsTypes.resetPassword && (
              <TextField
                sx={{ mt: 3 }}
                label="Enter the confirmation code"
                type="number"
                autoComplete="off"
                onKeyDown={onSubmitKeyHandler}
                value={localConfirmationCode}
                onChange={onConfirmationCodeChangeHandler}
                error={hasConfirmationCodeError || !!confirmationCodeHelperText}
                helperText={confirmationCodeHelperText}
                focused
              />
            )}
          </FormGroup>
          <Box
            sx={{ mt: 3 }}
            display="flex"
            justifyContent="center"
            alignItems="baseline"
            flexDirection="column"
            width="inherit"
          >
            {displayView === loginFormsTypes.resetPassword && (
              <LoadingButton
                variant="contained"
                fullWidth={true}
                onClick={resetPassword}
                disabled={!(username && confirmationCode) || !!confirmationCodeHelperText} //@TODO some redundancy here
                size="large"
                loading={isLoading}
              >
                Continue
              </LoadingButton>
            )}
            <LoadingButton
              variant={displayView === loginFormsTypes.resetPassword ? 'text' : 'outlined'}
              fullWidth={true}
              onClick={sendCode}
              disabled={isLoading || !localEmail || !!emailHelperText}
              size="large"
              loading={isLoading}
              className={displayView === loginFormsTypes.resetPassword ? 'code-again' : ''}
              sx={{ mt: displayView === loginFormsTypes.resetPassword ? 3 : 0 }}
            >
              {displayView === loginFormsTypes.resetPassword
                ? 'Resend a new confirmation code'
                : 'Send confirmation code'}
            </LoadingButton>

            <LinkStyled
              onClick={() => {
                backNavigation();
              }}
              sx={{
                mt: 3,
              }}
            >
              {displayView === loginFormsTypes.resetPassword ? 'Go back' : 'Return to sign in'}
            </LinkStyled>
          </Box>
        </div>
      </Fade>
    </React.Fragment>
  );
};

export default ResetPasswordForm;
