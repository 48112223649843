import React, { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormGroup, TextField } from '@mui/material';
import Box from '@mui/system/Box';
import PasswordInput from './PasswordInput';
import { checkEmailZod } from 'utils/validate';
import LinkStyled from 'components/linkStyled/linkStyled';
import { alertObjectType } from 'utils/types';

const LoginForm: React.FC<{
  username: string;
  password: string;
  setUsername: (newValue: string) => void;
  setPassword: (newValue: string) => void;
  errors: string;
  hasUsernameError: boolean;
  hasPasswordError: boolean;
  isLoading: boolean;
  forgetPasswordHandler: () => void;
  onLogin: () => void;
  setAlert: (alertObject: alertObjectType | undefined) => void;
}> = ({
  username,
  password,
  setUsername,
  setPassword,
  errors,
  hasUsernameError,
  hasPasswordError,
  isLoading,
  forgetPasswordHandler,
  onLogin,
  setAlert,
}) => {
  const [emailHelperText, setEmailHelperText] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);

  const onEmailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target?.value;

    setUsername(emailValue ? emailValue : '');

    if (!emailValue) {
      setEmailHelperText('');
    }

    if (emailValue.length > 0 && emailValue) {
      const isValidated = checkEmailZod(emailValue);
      setEmailIsValid(isValidated);
      if (!isValidated) {
        setEmailHelperText('Must be email format.');
        return;
      }
      setEmailHelperText('');
    }
  };

  const loginHandler = () => {
    if (username && password) {
      onLogin();
    }
  };

  useEffect(() => {
    if (errors) {
      setAlert({ type: 'warning', message: errors, timeout: 5000 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <React.Fragment>
      <FormGroup>
        <TextField
          autoComplete="username"
          sx={{ mb: 3, width: '100%' }}
          label={'Email address'}
          value={username}
          onChange={onEmailChangeHandler}
          error={hasUsernameError || !!emailHelperText}
          disabled={isLoading}
          helperText={emailHelperText}
          autoFocus
        />
        <PasswordInput
          isLoading={isLoading}
          errors={errors}
          password={password}
          hasPasswordError={hasPasswordError}
          onChange={setPassword}
          onSubmitKeyHandler={loginHandler}
        />

        <Box
          sx={{ mt: 5 }}
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          flexDirection="column"
          width="inherit"
        >
          <LoadingButton
            type="submit"
            onClick={loginHandler}
            variant="contained"
            fullWidth={true}
            disabled={!(emailIsValid && password)}
            size="large"
            loading={isLoading}
          >
            Sign In
          </LoadingButton>
          <LinkStyled
            sx={{ paddingTop: '24px' }}
            onClick={() => {
              forgetPasswordHandler();
            }}
          >
            Forgot password?
          </LinkStyled>
        </Box>
      </FormGroup>
    </React.Fragment>
  );
};

export default LoginForm;
